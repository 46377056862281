import type { Ref } from 'vue';
import { logger, Debouncer } from 'o365-utils';
import { onMounted, onBeforeUnmount, onUnmounted, ref, watch, computed } from 'vue';

export default function useVirtualScroll<T>(pOptions: {
    data: Ref<T[]>,
    dataLength?: Ref<number>,
    container: Ref<HTMLElement>,
    itemSize?: number,
    getItemSize?: (pItem: T) => number,
    buffer?: number,
    horizontal?: boolean,
    watchTarget?: any,
    noStartBuffer?: boolean,
    onEndReached?: () => void,
}) {
    const options = {
        itemSize: 34,
        buffer: 4,
        ...pOptions
    }

    const variableSizes = !!options.getItemSize;
    const elementsIndexes: Map<string, Number> = new Map();
    const scrollIndexMap = new Map<number, number>();
    const itemSizes: { size: number, pos: number }[] = [];
    let scrollDirection: 'start' | 'end' = 'end';
    let previousScroll = 0;
    let itemsToRender = 0;
    let previousStart = 0;

    let scrollContainer = options.container.value;

    let lastKnownContainerSize = 0;

    const totalSize: Ref<number> = ref(0);
    const currentPosition: Ref<number> = ref(0);
    const scrollData: Ref<VirtualItem<T>[]> = ref([]);

    const scrollDebouncer = new Debouncer(5);
    const observerSizeDebouncer = new Debouncer(50);
    const sizeUpdateDebouncer = new Debouncer(50);
    const transitionCleanupDebouncer = new Debouncer(400);

    const observer = new ResizeObserver((entries) => {
        const sizeProperty = options.horizontal
            ? 'width'
            : 'height';
        entries.forEach(entry => {
            const size = entry.contentRect[sizeProperty];
            if (size !== lastKnownContainerSize) {
                lastKnownContainerSize = size;
                observerSizeDebouncer.run(() => {
                    updateScrollItems();
                });
            }
        });
    });

    const positionStartBuffer = options.noStartBuffer
        ? 0
        : Math.floor(options.buffer / 2);

    let watcherCt: (() => void) | null = null;

    if (options.watchTarget) {
        watcherCt = watch(options.watchTarget, () => {
            updateScrollItems(true);
        });
    } else {
        const sortedSourceData = computed(() => {
            return [...options.data.value].sort();
        })
        watcherCt = watch(sortedSourceData, () => {
            updateScrollItems(true);
        });
        // watcherCt = watch(options.data, () => {
        //     updateScrollItems(true);
        // });
    }

    function getDataLength() {
        return options.dataLength
            ? options.dataLength.value
            : options.data.value.length;
    }

    function getItem(pIndex: number) {
        return options.data.value[pIndex];
    }

    function handleScroll(pEvent: Event) {
        scrollContainer = pEvent.target as HTMLElement;
        scrollDirection = (options.horizontal ? scrollContainer.scrollLeft : scrollContainer.scrollTop) < previousScroll
            ? 'start'
            : 'end';
        scrollDebouncer.run(() => {
            onScroll();
        });
    }

    function onScroll() {
        previousScroll = options.horizontal ? scrollContainer.scrollLeft : scrollContainer.scrollTop;
        let position = variableSizes
            ? binarySearchForIndex(previousScroll)
            : Math.round(previousScroll / options.itemSize);
        position = position > 0 ? position - 1 : position;
        const positionWithBuffer = position - positionStartBuffer;
        currentPosition.value = positionWithBuffer > 0 ? positionWithBuffer : 0;

        updateScrollItems();
    }

    function updateRenderCount() {
        const clientSize = options.horizontal ? scrollContainer.clientWidth : scrollContainer.clientHeight;
        itemsToRender = variableSizes
            ? getItemCountForSize(currentPosition.value, clientSize) + options.buffer
            : Math.round(clientSize / options.itemSize) + options.buffer;

        itemsToRender = Math.min(itemsToRender, getDataLength());
        if (itemsToRender < scrollData.value.length) {
            const fromIndex = currentPosition.value;
            const toIndex = currentPosition.value + itemsToRender - 1;
            const spliceIndexes: number[] = [];
            scrollData.value.forEach(item => {
                if (item.index < fromIndex || item.index > toIndex) {
                    spliceIndexes.push(item._index);
                    scrollIndexMap.delete(item.index);
                }
            });
            spliceIndexes.sort((a, b) => b - a).forEach(index => {
                scrollData.value.splice(index, 1);
            })
            scrollData.value.forEach((item, index) => {
                item._index = index;
            });
        }
    }

    function updateScrollItems(pForceUpdate = false) {
        if (variableSizes && (pForceUpdate || itemSizes.length === 0)) {
            getAllSizes(true);
        }
        updateRenderCount();

        let start = currentPosition.value;
        let end = start + itemsToRender;

        const dataLength = getDataLength();
        if (end > dataLength) {
            start -= end - dataLength;
            end = start + itemsToRender;
            currentPosition.value = start;
        }

        if (!pForceUpdate && previousStart === start && scrollData.value.length === itemsToRender) { return; }
        previousStart = start;
        const unusedIndexes: number[] = [];
        const missingIndexesSet = new Set(Array.from({ length: end - start }, (_, i) => i + start));
        if (pForceUpdate) {
            scrollIndexMap.clear();
            scrollData.value.forEach((_, index) => {
                unusedIndexes.push(index);
            });
        } else {
            scrollData.value.forEach((item, index) => {
                item.transition = undefined;
                if (item.index < start || item.index >= end) {
                    unusedIndexes.push(index);
                    scrollIndexMap.delete(item.index);
                } else {
                    missingIndexesSet.delete(item.index);
                }
            });
        }

        const missingIndexes = Array.from(missingIndexesSet);

        unusedIndexes.forEach((unusedIndex, index) => {
            const item = scrollData.value[unusedIndex];
            item.index = scrollDirection === 'end'
                ? missingIndexes.shift()!
                : missingIndexes.pop()!;
            // item.index = missingIndexes.pop()!;
            if (scrollIndexMap.has(item.index)) {
                logger.warn(`Virtual scroll item with index ${index} is already rednered`);
            }
            item.item = getItem(item.index);
            if (variableSizes) {
                const itemSize = getItemSize(item.index);
                item.itemSize = itemSize.size;
                item.pos = itemSize.pos;
            } else {
                item.pos = item.index * options.itemSize;
            }
            if (item.item == null) {
                item.isLoading = true;
            } else {
                item.isLoading = false;
            }
            item.updateSize = (pSize: number) => updateItemSize(item.index, pSize);
            if (item.item) {
                item.item.updateSize = (pSize: number) => updateItemSize(item.index, pSize);
            }

            scrollIndexMap.set(item.index, unusedIndex);
        });

        if (scrollData.value.length < itemsToRender) {
            for (let i = scrollData.value.length; i < itemsToRender; i++) {
                const index = scrollDirection === 'end'
                    ? missingIndexes.shift()!
                    : missingIndexes.pop()!;
                const item = getItem(index);

                let size: number | undefined = undefined;
                let pos: number | undefined = undefined;

                if (variableSizes) {
                    const itemSize = getItemSize(index);
                    size = itemSize.size;
                    pos = itemSize.pos;
                } else {
                    pos = index * options.itemSize;
                    size = options.itemSize;
                }

                if (scrollIndexMap.has(index)) {
                    logger.warn(`Virtual scroll item with index ${index} is already rednered`);
                }
                const scrollItem = {
                    _index: i,
                    index: index,
                    item: item,
                    isLoading: item == null,
                    pos: pos,
                    itemSize: size,
                    updateSize: (pSize: number) => updateItemSize(index, pSize),
                    get rowHeight() {
                        return this.itemSize
                    }
                };
                scrollData.value.push(scrollItem);
                scrollIndexMap.set(index, i);
            }
        }

        if (options.onEndReached) {
            if (end >= getDataLength()) {
                options.onEndReached();
            }
        }
    }


    /** Binary search for row index from scroll position */
    function binarySearchForIndex(pPosition: number) {
        let low = 0;
        let high = itemSizes.length;
        if (high === 0) { return 0; }
        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            const midPosition = itemSizes[mid].pos;

            if (midPosition === pPosition) {
                return mid;
            } else if (midPosition < pPosition) {
                low = mid + 1;
            } else {
                high = mid - 1;
            }
        }

        return low;
    }

    /** Get how many items would fit into the provided size from the provided index */
    function getItemCountForSize(pStart: number, pSize: number) {
        let count = 0;
        let accumulatedSize = 0;
        for (let i = pStart; i < itemSizes.length; i++) {
            accumulatedSize += itemSizes[i].size;
            if (accumulatedSize <= pSize) {
                count++;
            }
            if (accumulatedSize > pSize) {
                return count;
            }
        }
        return count;
    }

    function getItemSize(pIndex: number) {
        if (options.getItemSize == null) {
            throw new Error('Internal virtual scroll error: getItemSize executed when not in variable sizes mode');
        }
        if (itemSizes[pIndex] == null) {
            const item = getItem(pIndex);
            const size = options.getItemSize(item);
            const pos = 0;
            itemSizes[pIndex] = { size, pos };
        }
        return itemSizes[pIndex];
    }

    function getAllSizes(pClear = true) {
        if (!variableSizes) {
            throw new Error('Virtual Scroll: getAllSizes can only be called in variable sizes mode')
        }
        if (pClear) {
            itemSizes.splice(0, itemSizes.length);
        }
        let newTotalSize = 0;
        options.data.value.forEach((item, index) => {
            if (itemSizes[index]) {
                itemSizes[index].pos = newTotalSize;
                newTotalSize += itemSizes[index].size;
            } else {
                const size = options.getItemSize!(item);
                itemSizes[index] = { size: size, pos: newTotalSize };
                newTotalSize += size;
            }
        });
        totalSize.value = newTotalSize;
    }

    function updateItemSize(pIndex: number, pSize: number) {
        if (itemSizes[pIndex] == null) {
            itemSizes[pIndex] = { size: pSize, pos: 0 };
        } else if (itemSizes[pIndex].size == pSize) {
            return;
        } else {
            itemSizes[pIndex].size = pSize;
        }
        sizeUpdateDebouncer.run(() => {
            getAllSizes(false);
            scrollData.value.forEach(item => {
                item.transition = 'transform 0.4s,top 0.4s';
                const itemSize = itemSizes[item.index];
                item.itemSize = itemSize.size;
                item.pos = itemSize.pos;
            });
            transitionCleanupDebouncer.run(() => {
                scrollData.value.forEach(item => item.transition = undefined);
            });
        });
    }
    function updateItemSize2(pIndex: number, pSize: number) {
        if (itemSizes[pIndex] == null) {
            itemSizes[pIndex] = { size: pSize, pos: 0 };
        } else if (itemSizes[pIndex].size == pSize) {
            return;
        } else {
            itemSizes[pIndex].size = pSize;
        }
        sizeUpdateDebouncer.run(() => {
            getAllSizes(false);
            scrollData.value.forEach(item => {
                item.transition = 'transform 0.4s,top 0.4s';
                const itemSize = itemSizes[item.index];
                item.itemSize = itemSize.size;
                item.pos = itemSize.pos;
            });
            transitionCleanupDebouncer.run(() => {
                scrollData.value.forEach(item => item.transition = undefined);
            });
        });
    }

    function getPosByIndex(pIndex: number) {
        return itemSizes[pIndex]?.pos ?? pIndex * options.itemSize;
    }

    function getSizeByIndex(pIndex: number) {
        return itemSizes[pIndex]?.size ?? options.itemSize;
    }

    onMounted(() => {
        if (options.container.value == null) {
            logger.error('Cannot initialize virtual scroll, missing container element');
            return;
        }
        scrollContainer = options.container.value;

        updateScrollItems(true);
        observer.observe(options.container.value);
    });

    onBeforeUnmount(() => {
        observer.disconnect();
        if (watcherCt) {
            watcherCt();
        }
    });

    onUnmounted(() => {
        scrollData.value?.splice(0, scrollData.value.length);
    })

    return { handleScroll, scrollData, currentPosition, totalSize, updateItemSize, getPosByIndex, getSizeByIndex, updateScrollItems };
}

export type VirtualItem<T> = {
    /** Item value */
    item?: T;
    /** Index of scroll data array */
    _index: number,
    /** Index from source array */
    index: number,
    /** Absolute position of the item in the virtual list */
    pos: number,
    /** Height or width of the virtual item */
    itemSize?: number;
    /** Indicates if inner item value is filled or not */
    isLoading: boolean,
    /** Update size for current item, available only for variable row height mode */
    updateSize: (pSize: number) => void,
    /** Update size for current item, available only for variable row height mode testing */
    updateSize2: (pSize: number, pId: number) => void,

    /** Compatability for old virtual scroll */
    rowHeight?: number,
    /** Transition style */
    transition?: string,
};